import { graphql, useStaticQuery } from "gatsby";

const GlobalHeaderQuery = () => {
  const { sanityHeader } = useStaticQuery(graphql`
    query SiteGlobalHeaderQuery {
      sanityHeader(_id: { eq: "header" }) {
        _rawHeaderMenu(resolveReferences: { maxDepth: 15 })
      }
    }
  `);
  return sanityHeader;
};
export default GlobalHeaderQuery;
