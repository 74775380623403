import { graphql, useStaticQuery } from "gatsby";

const GlobalBannerQuery = () => {
  const { sanityBanner } = useStaticQuery(graphql`
    query SiteGlobalBannerQuery {
      sanityBanner(_id: { eq: "banner" }) {
        _rawBannerItems(resolveReferences: { maxDepth: 15 })
      }
    }
  `);
  return sanityBanner;
};
export default GlobalBannerQuery;
