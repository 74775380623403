import React from "react";

const Close = ({ className, height, width, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    width={height}
    height={width}
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="1.35355" y1="1.28921" x2="21.3536" y2="21.2892" />
    <line x1="0.646447" y1="21.2892" x2="20.6464" y2="1.28921" />
  </svg>
);
export default Close;
