import React from "react";
import cx from "classnames";

const Logo = ({ className, height, width, size = "large" }) => (
  <svg
    className={className}
    style={{ height, width }}
    viewBox="0 0 165 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.002 0.319336V4.56538H12.9856V4.56639C13.0331 4.56537 13.0816 4.56436 13.129 4.56436C19.1781 4.56436 24.0823 9.49102 24.0823 15.568C24.0823 21.6449 19.1781 26.5715 13.129 26.5715C13.0816 26.5715 13.0331 26.5705 12.9856 26.5695V26.5847H10.002V30.8308H28.6855V0.319336H10.002Z" />
    <path d="M5.40102 4.56543H0.799805V30.8318H5.40102V4.56543Z" />

    <g
      className={cx(
        size === "large" ? "opacity-100" : "opacity-0",
        "transition-opacity duration-300"
      )}
    >
      <path d="M68.5745 13.7003C68.5745 13.7003 67.3336 13.7013 65.8998 13.7013C63.2594 13.7013 61.2592 15.8993 61.2592 20.3716V30.8315H56.6943V9.6084H61.2592V15.028C62.2023 11.3135 64.0137 9.6084 67.1064 9.6084C68.0182 9.6084 68.5695 9.6084 68.5695 9.6084L68.5745 13.7003Z" />
      <path d="M142.396 13.7003C142.396 13.7003 140.486 13.7013 139.052 13.7013C136.412 13.7013 134.412 15.8993 134.412 20.3716V30.8315H129.847V9.6084H134.412V15.028C135.355 11.3135 137.166 9.6084 140.259 9.6084H140.678L142.396 13.7003Z" />
      <path d="M74.236 30.8316H69.6338V9.6085H74.236V30.8316ZM74.233 4.55606H69.6409V0.324219H74.233V4.55606Z" />
      <path d="M80.1313 30.8314H75.5664V9.6083H80.1313V14.5735C81.452 10.9726 83.8662 9.19141 87.2234 9.19141C90.8069 9.19141 93.109 11.1998 93.939 14.4974C95.3728 11.0111 97.9758 9.19141 101.333 9.19141C106.048 9.19141 108.577 12.6777 108.577 17.8326V30.8314H104.012V19.0072C104.012 15.3688 102.956 13.0195 99.4843 13.0195C96.1644 13.0195 94.354 15.6345 94.354 20.2964V30.8324H89.7891V18.9697C89.7891 15.2937 88.7329 13.0195 85.2616 13.0195C81.9417 13.0195 80.1313 15.7106 80.1313 20.3715V30.8314Z" />
      <path d="M151.849 39.0182H147.095L150.83 30.4907L142.002 9.6084H146.717L152.338 23.2523L153.206 26.057L154.149 23.2523L159.657 9.6084H164.297L151.849 39.0182Z" />
      <path d="M37.8698 19.5764V30.8316H33.2676V0.324219H44.1704C51.6028 0.324219 55.5255 3.92514 55.5638 9.64704C55.5638 15.9005 51.1121 19.5764 44.3975 19.5764H37.8698ZM37.8698 4.56823V16.0141H43.9815C48.5838 16.0141 51.0363 14.1193 51.0363 10.2536C51.0363 6.23682 48.5838 4.56924 44.0199 4.56924H37.8698V4.56823Z" />
      <path d="M128.457 24.8257C128.457 24.8236 128.457 24.8216 128.457 24.8196V16.789C128.457 11.8269 125.214 9.21289 119.935 9.21289C115.26 9.21289 111.527 11.4475 109.754 15.8416L113.864 17.1674C114.034 16.6906 114.226 16.2494 114.447 15.8457C115.507 13.9123 117.187 12.8493 119.745 12.8493C122.913 12.8493 124.081 14.2126 124.081 15.8041C124.081 17.3946 122.799 18.0387 119.029 18.8339C115.409 19.5916 113.448 20.1221 111.94 21.0685C110.319 22.091 109.264 23.4928 109.264 25.9171C109.264 29.1752 111.64 31.2201 115.899 31.2201C119.67 31.2201 122.8 29.1741 124.119 25.7274C124.119 27.4051 124.193 30.8306 124.193 30.8306H128.455V24.8257H128.457ZM124.235 20.8799C124.235 24.4788 121.332 27.7733 117.636 27.7733C115.147 27.7733 113.866 26.827 113.866 25.1594C113.866 22.9623 115.675 22.2046 119.447 21.3718C122.236 20.7652 123.594 20.2358 124.236 19.3644V20.8799H124.235Z" />
    </g>
  </svg>
);

export default Logo;
