import React from "react";
import cx from "classnames";
import Close from "./close";

const BurgerMenu = ({ className, menuOpened, onClick }) => {
  return (
    <div className={cx(className, "relative h-7 w-7")}>
      <svg
        className={cx(
          menuOpened ? "opacity-0" : "opacity-100",
          "transition-opacity duration-300 absolute"
        )}
        onClick={onClick}
        width="28"
        height="28"
        viewBox="0 0 27 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.21582" y1="1.04819" x2="26.7122" y2="1.04819" />
        <line x1="0.21582" y1="9.04816" x2="26.7122" y2="9.04816" />
        <line x1="0.21582" y1="17.0482" x2="26.7122" y2="17.0482" />
      </svg>
      <Close
        className={cx(
          menuOpened ? "opacity-100" : "opacity-0",
          "transition-opacity duration-300 absolute"
        )}
        onClick={onClick}
        width="28"
        height="28"
      />
    </div>
  );
};

export default BurgerMenu;
