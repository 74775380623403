import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

export const PageLink = (props) => {
  if (props._type === "externalLink") {
    let newTab = !props.link.includes("primary.vc");
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className={cx(
          props.className,
          props.currenPath === props.link && props.activeClassName
        )}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseOver={props.onMouseOver}
        onFocus={props.onFocus}
        href={props.link.includes("@") ? `mailto:${props.link}` : props.link}
        target={newTab ? "_blank" : undefined}
        rel={newTab ? "noreferrer" : undefined}
      >
        {props.children}
      </a>
    );
  }

  let link = props.link;
  if (props._type === "internalLink" && link) {
    if (link._type === "page" || link._type === "blogIndex") {
      link = link.page.content.slug.current;

      if (link === "home") {
        link = "/";
      } else {
        link = `/${link}`;
      }
    } else {
      console.error("Unknown internal link type: " + link._type);
    }
  } else {
    console.error("Unknown link type: " + props._type);
  }

  return (
    <Link
      type={props._type}
      className={cx(
        props.className,
        props.currenPath === link && props.activeClassName
      )}
      activeClassName="active"
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      onFocus={props.onFocus}
      to={link}
    >
      {props.children}
    </Link>
  );
};
