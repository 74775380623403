import React from "react";

const TargetBlank = ({ className, height, width }) => (
  <svg
    className={className}
    style={{ height, width }}
    width="18"
    height="17"
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8137 2.99977C14.8137 2.72363 14.5899 2.49977 14.3137 2.49977L9.81371 2.49977C9.53757 2.49977 9.31371 2.72363 9.31371 2.99977C9.31371 3.27591 9.53757 3.49977 9.81371 3.49977L13.8137 3.49977L13.8137 7.49977C13.8137 7.77591 14.0376 7.99977 14.3137 7.99977C14.5899 7.99977 14.8137 7.77591 14.8137 7.49977L14.8137 2.99977ZM3.35355 14.667L14.6673 3.35332L13.9602 2.64621L2.64645 13.9599L3.35355 14.667Z" />
  </svg>
);
export default TargetBlank;
