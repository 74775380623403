import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import Header from "../components/header";
import { TransitionWrapper } from "../components/transition";
import { useEffect } from "react";
import AOS from "aos";

const Layout = ({ children, location }) => {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Primary VC" />
      <TransitionWrapper trigger={location.pathname}>
        <div>
          <Header location={location} />
          <main>{children}</main>
          <Footer />
        </div>
      </TransitionWrapper>
    </React.Fragment>
  );
};

export default Layout;
