/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import AOS from "aos";
import "./src/styles/global.scss";
import "aos/dist/aos.css";
import queryString from "query-string";

export const onRouteUpdate = ({ location }) => {
  AOS.refresh();
  if (queryString.parse(location.search)["founder_slug"]) {
    // document.body.classList.add("disable-header-transition");
  }
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (!queryString.parse(location.search)["founder_slug"]) {
    if (location.action === "PUSH") {
      window.setTimeout(() => window.scrollTo(0, 0), 200);
    } else {
      const savedPosition = getSavedScrollPosition(location);
      window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        200
      );
    }
  }

  return false;
};
