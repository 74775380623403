import React, { useState } from "react";
import Logo from "../icons/logo";
import BurgerMenu from "../icons/burgerMenu";
import GlobalHeaderQuery from "../static-queries/globalHeaderQuery";
import { PageLink } from "./link";
import cx from "classnames";
import { Link } from "gatsby";
import * as styles from "./header.module.scss";
import useScrollPosition from "@react-hook/window-scroll";
import GlobalBannerQuery from "../static-queries/globalBannerQuery";
import Ticker from "react-ticker";
import Close from "../icons/close";
import { usePageVisibility } from "react-page-visibility";

const Header = (props) => {
  const isVisible = usePageVisibility();
  const headerData = GlobalHeaderQuery();
  const bannerData = GlobalBannerQuery();
  const [hasBanner, setHasBanner] = useState(
    bannerData && bannerData._rawBannerItems.length > 0
  );
  const [menuOpened, setMenuOpened] = useState(false);
  const [moveTicker, setMoveTicker] = useState(true);
  const scrollY = useScrollPosition(30);
  const isScrolled = scrollY > 15;

  if (typeof window !== "undefined" && typeof document !== "undefined") {
    if (menuOpened) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  const paths = ["/", "/people", "/approach", "/portfolio"];
  const bordered = paths.includes(props.location.pathname);
  const transparentBg = bordered;
  let className = "";

  if (menuOpened) {
    className = "bg-white text-dark";
  } else if (isScrolled) {
    className = "bg-dark text-white";
  } else if (!isScrolled) {
    if (transparentBg) {
      className = "text-white bg-transparent";
    } else {
      className = "text-dark md:bg-white";
    }
  }

  if (typeof document !== "undefined") {
    if (hasBanner && props.location.pathname === "/") {
      document.body.classList.add("has-banner");
    } else {
      document.body.classList.remove("has-banner");
    }
  }

  const getBannerItem = (index) => {
    const bannerItem =
      bannerData._rawBannerItems[index % bannerData._rawBannerItems.length];

    return (
      <a
        href={bannerItem.link}
        className={cx(
          "uppercase text-dark py-2.5 pl-5 pr-8 md:text-2xs text-4xs lg:py-2.5 lg:pl-7 lg:pr-10 border-t border-b border-l border-black whitespace-nowrap inline-block hover:underline -ml-3",
          `bg-${bannerItem.color}`
        )}
        target="_blank"
        rel="noreferrer"
      >
        {bannerItem.title}
      </a>
    );
  };

  return (
    <header
      className={cx(
        "top-0 w-screen z-50 transition-colors fixed",
        props.className,
        className
      )}
    >
      {!menuOpened &&
        hasBanner &&
        isVisible &&
        props.location.pathname === "/" && (
          <div
            onMouseOver={() => setMoveTicker(false)}
            onMouseLeave={() => setMoveTicker(true)}
            className="bg-white relative"
          >
            <Ticker move={moveTicker}>
              {({ index }) => getBannerItem(index)}
            </Ticker>
            <div
              className="bg-white text-dark border-black border absolute right-0 top-0 h-full p-2.5 cursor-pointer md:px-4"
              onClick={() => setHasBanner(false)}
            >
              <Close className="stroke-current" width="20" height="20" />
            </div>
          </div>
        )}
      <div className="container">
        <nav
          className={cx(
            "flex flex-row items-center",
            bordered && !isScrolled && "md:border-b border-current",
            isScrolled && styles.scrolled,
            styles.nav
          )}
        >
          <Link onClick={() => setMenuOpened(false)} to="/">
            <Logo
              className="fill-current hidden md:block transition-colors"
              height="40px"
              size={isScrolled && !menuOpened ? "small" : "large"}
            />
            <Logo
              className="fill-current md:hidden transition-colors"
              height="30px"
              size={isScrolled && !menuOpened ? "small" : "large"}
            />
          </Link>
          <div className="flex-1">
            <div className="hidden md:flex flex-row justify-end">
              {headerData._rawHeaderMenu.items.map((item) => (
                <PageLink
                  className="inline-block ml-7 hover:underline md:text-sm font-serif"
                  {...item}
                  key={item._key}
                  activeClassName="underline"
                  currenPath={props.location.pathname}
                >
                  {item.title}
                </PageLink>
              ))}
            </div>
            <div className="md:hidden flex content-center justify-end">
              <BurgerMenu
                className="stroke-current inline-block"
                menuOpened={menuOpened}
                onClick={() => setMenuOpened(!menuOpened)}
              />
            </div>
          </div>
        </nav>
      </div>

      <div
        className={cx(
          "md:hidden flex flex-col",
          styles.menu,
          menuOpened && styles.menuOpened
        )}
      >
        {headerData._rawHeaderMenu.items.map((item, index) => (
          <PageLink
            activeClassName="underline"
            currenPath={props.location.pathname}
            onClick={() => setMenuOpened(false)}
            {...item}
            className={cx(
              "flex-1 text-xl font-serif container flex items-center w-full",
              index === headerData._rawHeaderMenu.items.length - 1 &&
                "pb-safari",
              index === 0
                ? "bg-blue"
                : index === 1
                ? "bg-pink"
                : index === 2
                ? "bg-yellow"
                : index === 3
                ? "bg-ghost"
                : index === 4
                ? "bg-dark text-white"
                : "bg-white"
            )}
            key={item._key}
          >
            <span>{item.title}</span>
          </PageLink>
        ))}
      </div>
    </header>
  );
};

export default Header;
