import React, { useState } from "react";
import TargetBlank from "../icons/targetBlank";
import GlobalFooterQuery from "../static-queries/globalFooterQuery";
import { PageLink } from "./link";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Arrow from "../icons/arrow";

const Footer = () => {
  const { _rawFooterBottomLinks, _rawFooterLinkLists } = GlobalFooterQuery();
  const [mcResponse, setMcResponse] = useState({});

  const handleNewsletterSubmit = async (event) => {
    setMcResponse({});
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = await addToMailchimp(formData.get("email"));
    setMcResponse(response);
  };

  return (
    <footer className="bg-dark pt-20 pb-12 text-white font-serif md:text-sm">
      <div className="container">
        <div className="md:grid grid-cols-4 mb-20">
          {_rawFooterLinkLists.map(({ items, _id }) => (
            <div className="mb-12" key={_id}>
              {items.map(({ _key, title, link, _type }) => (
                <PageLink
                  className="block mb-2 hover:underline"
                  key={_key}
                  link={link}
                  _type={_type}
                >
                  {title}
                </PageLink>
              ))}
            </div>
          ))}
          <div>
            <h4 className="mb-3">Newsletter</h4>
            {mcResponse.result !== "success" && (
              <form
                onSubmit={handleNewsletterSubmit.bind(this)}
                className="w-full input--email"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  required
                />
                <button>
                  <Arrow />
                </button>
              </form>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: mcResponse.msg
                  ?.replace(" to list Updated Master Newsletter List", "")
                  ?.replace("Click here to update your profile", ""),
              }}
            />
          </div>
        </div>
        <div className="sm:grid grid-cols-2">
          <div className="mb-12 sm:mb-0">
            {_rawFooterBottomLinks.map((link) => (
              <PageLink
                className="inline-block hover:underline"
                target="_blank"
                {...link}
                key={link._key}
              >
                {link.title}
                <TargetBlank className="fill-current inline-block ml-1t" />
              </PageLink>
            ))}
          </div>
          <div className="sm:text-right">© {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
